export const realestateFiltersAdapter = (filters) => {
  let newFilters = {};
  const {
    locations = [],
    typeProperty = [],
    page,
    businessTypes,
    amountMax,
    amountMin,
    bedrooms,
    bathrooms,
    parkings,
    floorLevels,
    propertyStatus = [],
    exclusives = null,
    collection = null,
    onlyUnits = null,
    currencyType = "rd",
    improvements = [],
    sector = [],
    sectorName = null,
    cityName = null,
    orderBy = null,
    extraBusinessTypes = null,
    recent = null,
  } = filters;
  const { city, sectorCt } = getCityAndSector(locations);
  const propertyTypes = getTypeProperty(typeProperty);
  newFilters.sector = sectorCt + sector.map((x) => x.id).toString();
  newFilters.city = city;
  newFilters.type = propertyTypes;
  newFilters.page = page;
  newFilters.business = businessTypes;

  newFilters.bedrooms = bedrooms;
  newFilters.bathrooms = bathrooms;
  newFilters.parkings = parkings;
  newFilters.feature = getImprovements(improvements);
  newFilters.floorLevels = floorLevels;
  newFilters.exclusives = exclusives;
  newFilters.collection = collection;
  newFilters.onlyUnits = onlyUnits;
  newFilters.sectorName = sectorName ? sectorName.replaceAll("-", "+") : null;
  newFilters.cityName = cityName ? cityName.replaceAll("-", "+") : null;
  newFilters.extraBusinessTypes = Array.isArray(extraBusinessTypes)
    ? extraBusinessTypes[0]
    : null;
  if (orderBy) {
    console.log({ orderBy });
    newFilters.sort = "price";
    newFilters.direction = orderBy == "mayor" ? "desc" : "asc";
  }

  if (recent) {
    newFilters.recent = 1;
  }

  if (amountMax && amountMin) {
    newFilters.price = `${amountMin}...${amountMax},${currencyType}`;
  } else if (amountMin) {
    newFilters.price = `>=${amountMin},${currencyType}`;
  } else if (amountMax) {
    newFilters.price = `<=${amountMax},${currencyType}`;
  }
  if (newFilters.onlyUnits?.length == 2) {
    newFilters.onlyUnits = null;
  } else {
    newFilters.onlyUnits = onlyUnits[0];
  }

  if (propertyTypes.includes("project")) {
    const allTypes = propertyTypes.split(",").filter((x) => x !== "project");
    newFilters.type = allTypes.join(",");
    newFilters.onlyUnits = "false";
  }

  // // Filtro construidas
  // if (propertyStatus.includes("2") ) {
  //   newFilters.onlyUnitss = true;
  // } else {
  //   newFilters.onlyUnitss = false;
  // }

  // // Filtro En construcción
  // if (propertyStatus.includes("1")) {
  //   newFilters.type = "project";
  // }

  // if (propertyStatus.includes("1") && propertyStatus.includes("2")) {
  //   newFilters.type = propertyTypes;
  //   newFilters.onlyUnitss = false;
  // }

  if (bedrooms?.length > 0) {
    newFilters.bedrooms = bedrooms.join(",");
  }

  let usedFilters = {};

  // Getting rid of empty string/nulls/false
  Object.keys(newFilters).forEach((keys) => {
    if (!isNull(newFilters[keys])) {
      usedFilters[keys] = newFilters[keys];
    }
  });

  return usedFilters;
};

function getCityAndSector(locations) {
  let city = [];
  let sector = [];

  for (let index = 0; index < locations.length; index++) {
    const element = locations[index];

    if (element.isSector) {
      if (!sector.includes(element.id)) {
        sector.push(element.id);
      }
      // if (!city.includes(element.city_id)) {
      //   city.push(element.city_id);
      // }
    } else {
      if (!city.includes(element.id)) {
        city.push(element.id);
      }
    }
  }

  return { city: city.join(","), sectorCt: sector.join(",") };
}

function getTypeProperty(types = []) {
  return types
    .map((item) => {
      if (item.slug) {
        return item.slug;
      }

      if (item.value) {
        return item.value;
      }

      if (typeof item === "string") {
        return item;
      }

      return "";
    })
    .join(",");
}

function getImprovements(improvements = []) {
  return improvements
    .map((item) => {
      if (item.value) {
        return item.value;
      }
      if (typeof item === "string") {
        return item;
      }

      return "";
    })
    .join(",");
}

const isNull = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (value) {
    return false;
  }

  return true;
};
